<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >名称在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="11" style="margin: 0 auto; text-align: center;">
			<el-form :model="nameData">
				<el-form-item label="生成数量">
						<el-col :sm = "24">
							<el-input v-model.number="nameData.size" placeholder="请输入1-1000范围整数"></el-input>
						</el-col>
				</el-form-item>
				
				<el-form-item>
					<el-col :sm = "20">
						<el-radio-group v-model="nameData.type">
							<el-radio v-model="radio" label="1">企业名称</el-radio>
							<el-radio v-model="radio" label="2">姓名</el-radio>
						</el-radio-group>
					</el-col>
				</el-form-item>
				
				<el-form-item label="">
					<el-col :sm = "18"></el-col>
					<el-input readonly  type="textarea" :rows="nameData.rows" resize='none'  v-model="nameData.text"></el-input>
				</el-form-item>
				<div style="margin: 0 auto; text-align: center;">
					<el-button id="1001" type="primary" @click="generate()">生成</el-button>
					<el-button  @click="copy(nameData.text)" type="primary" plain>复制</el-button>
				</div>
			</el-form>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {generate as nameGenerate} from './name'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'	
import {clipboard as copy} from '../../common/actionrecord'	
    let nameData = ref({
		moduleid: 1002,
        size: 10,
        rows: 10,
		type: "1",
        text: ""
    })
	moduleaction(nameData.value.moduleid,1);
    let generate = () => {
		//校验代码
		var validresult = checknumber(nameData.value.size,1000);
		if(!validresult.success){
			nameData.value.text = validresult.info;
			return;
		}
		nameData.value.text = nameGenerate(parseInt(nameData.value.size),nameData.value.type).join("\n");
		//模块事件 1.预览 2.使用
		moduleaction(nameData.value.moduleid,2);
	}
//进入页面，触发数据生成逻辑
generate();	
</script>

<style>

</style>
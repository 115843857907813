let random = (lower, upper) => {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
}

/**
 * 姓库
 */
var findSurname = new Array(
    "赵","钱","孙","李","周","吴","郑","王","冯","陈","褚","卫","蒋","沈","韩","杨","朱","秦","尤","许","何","吕","施","张","孔","曹","严","华","金","魏","陶","姜","戚","谢","邹","喻","柏","水","窦",
    "苏","潘","葛","奚","范","彭","郎","鲁","韦","昌","马","苗","凤","花","方","俞","任","袁","柳","酆","鲍","史","唐","费","廉","岑","薛","雷","贺","倪","汤","滕","殷","罗","毕","郝","邬","安","常",
    "时","傅","皮","卞","齐","康","伍","余","元","卜","顾","孟","平","黄","和","穆","萧","尹","姚","邵","湛","汪","祁","毛","禹","狄","米","贝","明","臧","计","伏","成","戴","谈","宋","茅","庞","熊",
    "屈","项","祝","董","梁","杜","阮","蓝","闵","席","季","麻","强","贾","路","娄","危","江","童","颜","郭","梅","盛","林","刁","钟","徐","邱","骆","高","夏","蔡","田","樊","胡","凌","霍","虞","万",
    "咎","管","卢","莫","经","房","裘","缪","干","解","应","宗","宣","丁","贲","邓","郁","单","杭","洪","包","诸","左","石","崔","吉","钮","龚","程","嵇","邢","滑","裴","陆","荣","翁","荀","羊","於",
    "曲","家","封","芮","羿","储","靳","汲","邴","糜","松","井","段","富","巫","乌","焦","巴","弓","牧","隗","山","谷","车","侯","宓","蓬","全","郗","班","仰","秋","仲","伊","宫","宁","仇","栾","暴",
    "厉","戎","祖","武","符","刘","景","詹","束","龙","叶","幸","司","韶","郜","黎","蓟","薄","印","宿","白","怀","蒲","邰","从","鄂","索","咸","籍","赖","卓","蔺","屠","蒙","池","乔","阴","鬱","胥",
    "双","闻","莘","党","翟","谭","贡","劳","逄","姬","申","扶","堵","冉","宰","郦","雍","却","璩","桑","桂","濮","牛","寿","通","边","扈","燕","冀","郟","浦","尚","农","温","别","庄","晏","柴","瞿",
    "慕","连","茹","习","宦","艾","鱼","容","向","古","易","慎","戈","廖","庾","终","暨","居","衡","步","都","耿","满","弘","匡","国","文","寇","广","禄","阙","东","欧","殳","沃","利","蔚","越","夔",
    "巩","厍","聂","晁","勾","敖","融","冷","訾","辛","阚","那","简","饶","空","曾","毋","沙","乜","养","鞠","须","丰","巢","关","蒯","相","查","后","荆","红","游","竺","权","逯","盖","益","桓","公",
    "司","马","上","官","欧","阳","夏","侯","诸","葛","闻","人","东","方","赫","连","皇","甫","尉","迟","公","羊","澹","台","公","冶","宗","政","濮","阳","淳","于","单","于","太","叔","申","屠","公",
    "孙","轩","辕","令","狐","钟","离","宇","文","长","孙","慕","容","鲜","于","闾","丘","司","徒","司","空","亓","官","司","寇","仉","督","子","车","颛","孙","端","木","巫","马","公","西","漆","雕",
    "壤","驷","公","良","拓","拔","夹","谷","宰","父","谷","粱","晋","楚","闫","法","汝","鄢","涂","钦","段","干","百","里","东","郭","南","门","呼","延","归","海","羊","舌","微","生","岳","帅","缑",
    "后","有","琴","梁","丘","左","丘","东","门","西","门","商","牟","佘","佴","伯","赏","南","宫","墨","哈","谯","笪","年","爱","阳","佟","第","五","言","福","百","家","姓","终","章","云","乐","于",
    "纪","舒","支","柯","惠","甄","甘","钭","能","苍","阎","充","隆","师","万","俟","孙","仲","乐","正","亢","况"
);

/**
 * 名库
 */
var findName = new Array(
    "璇","颖","宁","佳","乐","妮","一","华","琴","顺","静","齐","洋","臻","萤",
    "东","宇","颜","君","刚","芳","燕","萌","超","涛","仑","易","鑫","楠","玉",
    "妍","飞","青","琳","琦","莉","讯","斌","彬","勋","亮","烨","葵","极","兆",
    "子璇", "淼", "国栋", "夫子", "瑞堂", "甜", "敏", "尚", "国贤", "贺祥", "晨涛", 
    "昊轩", "易轩", "益辰", "益帆", "益冉", "瑾春", "瑾昆", "春齐", "杨", "文昊", 
    "东东", "雄霖", "浩晨", "熙涵", "溶溶", "冰枫", "欣欣", "宜豪", "欣慧", "建政", 
    "美欣", "淑慧", "文轩", "文杰", "欣源", "忠林", "榕润", "欣汝", "慧嘉", "新建", 
    "建林", "亦菲", "林", "冰洁", "佳欣", "涵涵", "禹辰", "淳美", "泽惠", "伟洋", 
    "涵越", "润丽", "翔", "淑华", "晶莹", "凌晶", "苒溪", "雨涵", "嘉怡", "佳毅", 
    "子辰", "佳琪", "紫轩", "瑞辰", "昕蕊", "萌", "明远", "欣宜", "泽远", "欣怡", 
    "佳怡", "佳惠", "晨茜", "晨璐", "运昊", "汝鑫", "淑君", "晶滢", "润莎", "榕汕", 
    "佳钰", "佳玉", "晓庆", "一鸣", "语晨", "添池", "添昊", "雨泽", "雅晗", "雅涵", 
    "清妍", "诗悦", "嘉乐", "晨涵", "天赫", "玥傲", "佳昊", "天昊", "萌萌", "若萌"
);

var company = new Array(
        "超艺",
        "和泰",
        "九方",
        "鑫博腾飞",
        "戴硕电子",
        "济南亿次元",
        "海创",
        "创联世纪",
        "凌云",
        "泰麒麟",
        "彩虹",
        "兰金电子",
        "晖来计算机",
        "天益",
        "恒聪百汇",
        "菊风公司",
        "惠派国际公司",
        "创汇",
        "思优",
        "时空盒数字",
        "易动力",
        "飞海科技",
        "华泰通安",
        "盟新",
        "商软冠联",
        "图龙信息",
        "易动力",
        "华远软件",
        "创亿",
        "时刻",
        "开发区世创",
        "明腾",
        "良诺",
        "天开",
        "毕博诚",
        "快讯",
        "凌颖信息",
        "黄石金承",
        "恩悌",
        "雨林木风计算机",
        "双敏电子",
        "维旺明",
        "网新恒天",
        "数字100",
        "飞利信",
        "立信电子",
        "联通时科",
        "中建创业",
        "新格林耐特",
        "新宇龙信息",
        "浙大万朋",
        "MBP软件",
        "昂歌信息",
        "万迅电脑",
        "方正科技",
        "联软",
        "七喜",
        "南康",
        "银嘉",
        "巨奥",
        "佳禾",
        "国讯",
        "信诚致远",
        "浦华众城",
        "迪摩",
        "太极",
        "群英",
        "合联电子",
        "同兴万点",
        "襄樊地球村",
        "精芯",
        "艾提科信",
        "昊嘉",
        "鸿睿思博",
        "四通",
        "富罳",
        "商软冠联",
        "诺依曼软件",
        "东方峻景",
        "华成育卓",
        "趋势",
        "维涛",
        "通际名联"
);

var ext = new Array(
"科技", "网络", "信息", "传媒","技术"
);

export let generate = (size,type) => {
    let names = new Array(size)
    .fill()
    .map(() => {
		var name="";
		if(type=="1"){
			var companyName = company[random(0, company.length-1)];
			var extName = ext[random(0, ext.length-1)];
			name = companyName + extName+"有限公司";
		}
		if(type=="2"){
			var familyName = findSurname[random(0, findSurname.length-1)];
			var givenName = findName[random(0, findName.length-1)];
			name = familyName + givenName;
		}
        return name;
    });
    return names;
}
